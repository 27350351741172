// CookieConsentComponent.tsx
import React, { useState } from 'react';
import CookieConsent from 'react-cookie-consent';

const CookieConsentComponent: React.FC = () => {
  const [isHoverAccept, setIsHoverAccept] = useState(false);

  const handleMouseEnterAccept = () => setIsHoverAccept(true);
  const handleMouseLeaveAccept = () => setIsHoverAccept(false);

  return (
    <div onMouseEnter={handleMouseEnterAccept} onMouseLeave={handleMouseLeaveAccept}>
      <CookieConsent
        location="bottom"
        buttonText="Aceptar"
        cookieName="ccedCookies"
        style={{ background: "#212828" }}
        buttonStyle={{
          background: isHoverAccept ? "#014B47" : "#02514D",
          color: "#ffffff",
          fontWeight: "bold",
          padding: "10px 20px",
          borderRadius: "5px",
          transition: "background-color 0.3s ease",
        }}
        expires={150}
      >
        Este sitio web utiliza cookies para brindarle la mejor experiencia. Al continuar navegando, acepta el uso de cookies.{" "}
        <a
          href="/aviso-de-privacidad"
          className="text-teal-500 underline transition-colors duration-300 hover:text-teal-600"
        >
          Más información
        </a>
      </CookieConsent>
    </div>
  );
};

export default CookieConsentComponent;
