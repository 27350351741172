import { BrowserRouter as Router } from 'react-router-dom';
import { Suspense, lazy, memo } from 'react';
import { SpeedInsights } from "@vercel/speed-insights/react";
import Loader from './components/Loader';

const Header = lazy(() => import('./components/Header'));
const Footer = lazy(() => import('./components/Footer'));
const AnimatedRoutes = lazy(() => import('./components/AnimatedRoutes'));

const App: React.FC = memo(() => {
  return (
    <Router basename="/">
      <div className="bg-[#212828] min-h-screen flex flex-col">
        <Suspense fallback={<Loader />}>
          <Header />
        </Suspense>
        <SpeedInsights />
        <main className="flex-grow bg-[#212828]">
          <Suspense fallback={<Loader />}>
            <AnimatedRoutes />
          </Suspense>
        </main>
        <Suspense fallback={<Loader />}>
          <Footer />
        </Suspense>
      </div>
    </Router>
  );
});

export default App;
