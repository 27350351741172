// analytics.ts
import ReactGA from 'react-ga4';

const initializeAnalytics = () => {
  ReactGA.initialize('G-VVND37W201');
};

const sendToAnalytics = ({ id, name, value }: { id: any, name: any, value: any }) => {
  const roundedValue = Math.round(name === 'CLS' ? value * 1000 : value);
  ReactGA.event({
    category: 'Web Vitals',
    action: name,
    label: id,
    value: roundedValue,
    nonInteraction: true,
  });
};

export { initializeAnalytics, sendToAnalytics };
