// index.tsx
import ReactDOM from 'react-dom/client';
import './index.css';
import RootComponent from './RootComponent';
import { initializeAnalytics, sendToAnalytics } from './utils/analytics'
import reportWebVitals from './reportWebVitals';

const rootElement = document.getElementById('root');
if (!rootElement) throw new Error('Failed to find the root element');

const root = ReactDOM.createRoot(rootElement);

initializeAnalytics();
root.render(<RootComponent />);

// Ensure that reportWebVitals is called after the root is rendered
reportWebVitals(sendToAnalytics);
