import React from 'react';

/**
 * Props for the WarningModal component.
 * @property {boolean} open - Determines if the modal is open.
 * @property {() => void} handleClose - Function to handle closing the modal.
 */
interface WarningModalProps {
  open: boolean;
  handleClose: () => void;
}

/**
 * WarningModal component to display a fraud warning message.
 * @param {WarningModalProps} props - Props for the component.
 * @returns {JSX.Element | null} The WarningModal component.
 */
const WarningModal: React.FC<WarningModalProps> = ({ open, handleClose }) => {
  if (!open) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
      <div className="relative bg-[#D9D9D9] w-full max-w-4xl p-6 sm:p-10 rounded-3xl shadow-lg">
        <button
          onClick={handleClose}
          className="absolute top-0 right-0 md:right-2 md:top-2 text-[#737373] hover:text-gray-600 p-2"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <div className="flex items-center justify-center mb-4 sm:mb-6">
          <img
            src="/static/warning_icon.png"
            alt="Advertencia"
            className="w-6 h-6 sm:w-8 sm:h-8 mr-2"
          />
          <h2 className="text-lg sm:text-xl font-semibold text-center">
            Advertencia sobre fraudes
          </h2>
        </div>
        <p className="mb-2 sm:mb-4 text-justify text-sm sm:text-base">
          PITIC CAPITAL S.A.P.I de C.V., SOFOM E.N.R. informa al público en
          general que nuestra atención siempre se brinda de manera personalizada
          a través de nuestros medios de comunicación oficiales. Hacemos un
          llamado a la precaución ante cualquier ofrecimiento de financiamiento
          realizado por correo electrónico, llamadas o mensajes de texto que
          soliciten datos sensibles y/o inciten a realizar algún depósito.
        </p>
        <p className="mb-2 sm:mb-4 text-justify text-sm sm:text-base">
          Recomendamos NO efectuar depósito alguno ni entregar información. Por
          lo anterior, se informa que la única página de la marca es{' '}
          <a
            href="https://www.piticcapital.mx"
            className="text-blue-600 underline"
          >
            www.piticcapital.com.mx
          </a>{' '}
          y nuestro teléfono de servicio es{' '}
          <a
            href="tel:+526622854872"
            className="text-blue-600 underline"
          >
            (662) 285 48 72
          </a>
        </p>
        <p className="mb-2 sm:mb-4 text-justify text-sm sm:text-base">
          PITIC CAPITAL S.A.P.I de C.V., SOFOM E.N.R. no asume responsabilidad
          alguna por la contratación de productos y/o servicios sin nuestra
          debida representación y autorización.
        </p>
        <p className="text-justify text-sm sm:text-base">
        En caso de haber sido víctima de fraude, favor de denunciar en el siguiente{' '}
          <a
            href="https://phpapps.condusef.gob.mx/fraudes_financieros/index.php"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 underline"
          >
            enlace
          </a>{' '}
          o, si existe alguna duda, contactarnos vía correo electrónico{' '}
          <a
            href="mailto:contacto@piticcapital.com.mx"
            className="text-blue-600 underline"
          >
            contacto@piticcapital.com.mx
          </a>
        </p>
      </div>
    </div>
  );
};

export default WarningModal;
